import React from 'react';
import Navbar from '../components/navbar/navbar';

const Noaccess = () => {
    return (
        <>
            <Navbar />
            <div className='container noaccess'>
                <h2>Accès refusé</h2>
                <h3>Page non accessible</h3>
                <img src='/pictures/Acces_interdit_403.jpg' alt="Logo accès refusé" />
                <p>Merci de vous <a href="/connexion">connecter</a> ou de vous <a href="/inscription">enregistrer</a>.</p>
            </div>
        </>
    );
};

export default Noaccess;