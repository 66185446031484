import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { BASE_URL } from '../../../config/config';
import axios from 'axios';
import { toast } from 'react-toastify';

const ProductUpdateAdmin = () => {

    const navigate = useNavigate();
    const { id } = useParams();
    const [nameProduct, setNameProduct] = useState("");
    const [descriptionProduct, setDescriptionProduct] = useState("");
    const [priceProduct, setPriceProduct] = useState("");
    const [stock, setStock] = useState("");
    const [slugProduct, setSlugProduct] = useState("");
    const [productStripeId, setProductStripeId] = useState("");
    const [priceStripeId, setPriceStripeId] = useState("");
    const [category_id, setCategoryId] = useState("");
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/products/${id}`);
                const product = response.data;
                if (response.data) {
                    setNameProduct(product.nameProduct);
                    setDescriptionProduct(product.descriptionProduct);
                    setPriceProduct(product.priceProduct);
                    setStock(product.stock);
                    setSlugProduct(product.slugProduct);
                    setProductStripeId(product.productStripeId);
                    setPriceStripeId(product.priceStripeId);
                    setCategoryId(product.category_id);
                }
            } catch (error) {
                console.error('Erreur lors de la récupération du produit', error);
            }
        };
        getCategories();
        fetchProduct();
    }, [id]);

    const getCategories = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/categories`);
            setCategories(response.data)
        } catch (error) {
            console.error('Erreur lors de la récupération des catégories', error);
        }
    }

    const handleCategory = (event) => {
        setCategoryId(event.target.value);
    }

    const updateProduct = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append("nameProduct", nameProduct);
        formData.append("descriptionProduct", descriptionProduct);
        formData.append("priceProduct", priceProduct);
        formData.append("stock", stock);
        formData.append("slugProduct", slugProduct);
        formData.append("category_id", category_id);
        formData.append("_method", "PUT");
        try {
            const response = await axios.post(`${BASE_URL}/products/${id}`, formData, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                }
            });
            toast.success(response.data.message);
            navigate("/admin/produits/liste");
        } catch (error) {
            console.error('Error lors de l\'update du produit', error);
            if (error.response && error.response.data) {
                toast.warn(error.response);
            }
        }
    }

    return (
        <>
            <div className="admin-form-container">
                <form className='admin-form' onSubmit={updateProduct}>
                    <h1>Modifier un produit</h1>
                    <label className="label">
                        Nom:
                        <input
                            className='input'
                            type="text"
                            name="nameProduct"
                            value={nameProduct}
                            onChange={(event) => {
                                setNameProduct(event.target.value)
                            }}
                        />
                    </label>
                    <label className='label'>
                        Description:
                        <textarea
                            cols="30"
                            rows="6"
                            className='input'
                            name="descriptionProduct"
                            value={descriptionProduct}
                            onChange={(event) => {
                                setDescriptionProduct(event.target.value)
                            }}
                        />
                    </label>
                    <label className="label">
                        Prix:
                        <input
                            className='input'
                            type="text"
                            name="priceProduct"
                            value={priceProduct}
                            onChange={(event) => {
                                setPriceProduct(event.target.value)
                            }}
                        />
                    </label>
                    <label className="label">
                        Stock:
                        <input
                            className='input'
                            type="text"
                            name="stock"
                            value={stock}
                            onChange={(event) => {
                                setStock(event.target.value)
                            }}
                        />
                    </label>
                    <label className="label">
                        Slug:
                        <input
                            className='input'
                            type="text"
                            name="slugProduct"
                            value={slugProduct}
                            onChange={(event) => {
                                setSlugProduct(event.target.value)
                            }}
                        />
                    </label>
                    <label className='label'>Catégorie:</label>
                    <select onChange={handleCategory} value={category_id}>
                        <option>Choisissez une catégorie</option>
                        {categories.map(category => (
                            <option
                                key={category.id}
                                value={category.id}>
                                {category.nameCategory}
                            </option>
                        ))}
                    </select>
                    <label className="label">
                        Id Stripe:
                        <p>{productStripeId}</p>
                    </label>
                    <label className="label">
                        Prix Stripe:
                        <p>{priceStripeId}</p>
                    </label>
                    <div className='admin-form-bot'>
                        <button className='admin-form-btn' type="submit">Modifier</button>
                        <button className='admin-form-btn-cancel'>
                            <Link to="/admin/produits/liste" >
                                Annuler
                            </Link>
                        </button>
                    </div>
                </form>
            </div>
        </>
    );
};

export default ProductUpdateAdmin;