import { createContext, useContext, useEffect, useState } from "react";
import token from '../services/auth/token';

const AuthContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
    const [isConnected, setIsConnected] = useState(false);
    const [tokenOn, setTokenOn] = useState(null);
    const [roles, setRoles] = useState("");
    const [name, setName] = useState("");
    const [id, setId] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const storedToken = localStorage.getItem("token");
        if (storedToken) {
            setTokenOn(storedToken);
            setRoles(token.getRoles());
            setName(token.getName());
            setId(token.getId());
            setIsConnected(true);
        }
        setIsLoaded(true);
    }, [tokenOn]);

    const login = (token, roles) => {
        setIsConnected(true);
        setTokenOn(token);
        setRoles(roles);
        localStorage.setItem("token", token);
    };

    const logout = () => {
        setIsConnected(false);
        setTokenOn(null);
        localStorage.removeItem("token");
    };

    return (
        <AuthContext.Provider value={{ isConnected, tokenOn, login, logout, roles, name, id, isLoaded }}>
            {children}
        </AuthContext.Provider>
    );
};
