import React from 'react';
import Navbar from '../../components/navbar/navbar';

const CancelPage = () => {
    return (
        <>
            <Navbar />
            <div className='payment'>
                <h4>Paiement annulé.</h4>
                <p>Votre paiement a été annulé. Vous pouvez continuer vos achats ou réessayer plus tard.</p>
            </div>
        </>

    );
};

export default CancelPage;