import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from './pages/Home';
import ProductDetails from './components/products/productDetails';
import ShoppingCart from './components/shoppingCart/shoppingCart';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import RegisterPage from './pages/RegisterPage';
import LoginPage from './pages/LoginPage';
import Logout from './components/logout/logout';
import Profile from './pages/Profile';
import Arcade from './pages/Arcade';
import Parts from './pages/Parts';
import Admin from './pages/Admin';
import ProductsListAdmin from './components/admin/products/productsListAdmin';
import ProductUpdateAdmin from './components/admin/products/productUpdateAdmin';
import ProductAddAdmin from './components/admin/products/productAddAdmin';
import CategoriesListAdmin from './components/admin/categories/categoriesListAdmin';
import CategoryAddAdmin from './components/admin/categories/categoryAddAdmin';
import CategoryUpdateAdmin from './components/admin/categories/categoryUpdateAdmin';
import SuccessPage from './pages/Payment/SuccessPage';
import CancelPage from './pages/Payment/CancelPage';
import PicturesAddAdmin from './components/admin/pictures/picturesAddAdmin';
import ProtectedRoute from './components/protectedRoute/protectedRoute';
import Dashboard from './components/admin/dashboard/dashboard';
import OrdersListAdmin from './components/admin/orders/ordersListAdmin';
import OrderDetailAdmin from './components/admin/orders/orderDetailAdmin';
import UsersListAdmin from './components/admin/users/usersListAdmin';
import Contact from './pages/Contact';
import ContactsListAdmin from './components/admin/contacts/contactsListAdmin';
import Notice from './pages/Notice';
import PersonalData from './pages/PersonalData';
import Cgv from './pages/Cgv';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';

function App() {

  return (
    <>
      <BrowserRouter>
        <ToastContainer
          position="top-center"
          autoClose={4000}
          pauseOnHover={false}
          theme="colored"
        />
        <Routes>
          <Route path='*' element={<Home />} />
          <Route path='/accueil' element={<Home />} />
          <Route path='/produit/:slugProduct/:id' element={<ProductDetails />} />
          <Route path='/panier' element={<ShoppingCart />} />
          <Route path='/inscription' element={<RegisterPage />} />
          <Route path='/connexion' element={<LoginPage />} />
          <Route path='/deconnexion' element={<Logout />} />
          <Route path='/profile' element={<Profile />} />
          <Route path='/joysticks' element={<Arcade />} />
          <Route path='/composants' element={<Parts />} />
          <Route path='/admin' element={<ProtectedRoute><Admin><Dashboard /></Admin></ProtectedRoute>} />
          <Route path='/admin/produits/liste' element={<ProtectedRoute><Admin><ProductsListAdmin limit={6} /></Admin></ProtectedRoute>} />
          <Route path='/admin/produits/ajouter' element={<ProtectedRoute><Admin><ProductAddAdmin /></Admin></ProtectedRoute>} />
          <Route path='/admin/produit/modifier/:id' element={<ProtectedRoute><Admin><ProductUpdateAdmin /></Admin></ProtectedRoute>} />
          <Route path='/admin/categories/liste' element={<ProtectedRoute><Admin><CategoriesListAdmin /></Admin></ProtectedRoute>} />
          <Route path='/admin/categories/ajouter' element={<ProtectedRoute><Admin><CategoryAddAdmin /></Admin></ProtectedRoute>} />
          <Route path='/admin/categorie/modifier/:id' element={<ProtectedRoute><Admin><CategoryUpdateAdmin /></Admin></ProtectedRoute>} />
          <Route path='/admin/images/produit/:id' element={<ProtectedRoute><Admin><PicturesAddAdmin /></Admin></ProtectedRoute>} />
          <Route path='/admin/commandes/liste' element={<ProtectedRoute><Admin><OrdersListAdmin /></Admin></ProtectedRoute>} />
          <Route path='/admin/commande/details/:id' element={<ProtectedRoute><Admin><OrderDetailAdmin /></Admin></ProtectedRoute>} />
          <Route path='/admin/contacts/liste' element={<ProtectedRoute><Admin><ContactsListAdmin /></Admin></ProtectedRoute>} />
          <Route path='/admin/clients/liste' element={<ProtectedRoute><Admin><UsersListAdmin /></Admin></ProtectedRoute>} />
          <Route path='/contact' element={<Contact />} />
          <Route path="/payment/success" element={<SuccessPage />} />
          <Route path="/payment/cancel" element={<CancelPage />} />
          <Route path='/mentions-legales' element={<Notice />} />
          <Route path='/politique-de-confidentialite' element={<PersonalData />} />
          <Route path='/cgv' element={<Cgv />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/reset-password/:token' element={<ResetPassword />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
