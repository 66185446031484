import axios from 'axios';
import React, { useState } from 'react';
import { BASE_URL } from '../../../config/config';
import { toast } from 'react-toastify';

const CategoryAddAdmin = ({ onCategoryAdded }) => {


    const [nameCategory, setNameCategory] = useState("");

    const addCategory = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append("nameCategory", nameCategory);

        try {
            const response = await axios.post(`${BASE_URL}/categories`, formData, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                }
            });
            toast.success(response.data.message);
            setNameCategory("");

            if (onCategoryAdded) {
                onCategoryAdded(response.data.category);
            }

        } catch (error) {
            console.error('Error adding category', error);
        }
    }

    return (
        <>
            <form className='admin-form' onSubmit={addCategory} >
                <h2>Ajouter une catégorie</h2>
                <label className='label'>
                    Nom:
                    <input
                        className='input'
                        type="text"
                        name='nameCategory'
                        value={nameCategory}
                        onChange={(event) => { setNameCategory(event.target.value) }}
                    />
                </label>
                <button className='admin-form-btn' type="submit">Sauvegarder</button>
            </form>
        </>
    );
};

export default CategoryAddAdmin;