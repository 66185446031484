import React, { useState } from 'react';
import axios from 'axios';
import Navbar from '../components/navbar/navbar';
import Footer from '../components/footer/footer';
import { BASE_URL } from '../config/config';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${BASE_URL}/password/email`, { email });
            setMessage(response.data.message);
        } catch (error) {
            setMessage('Une erreur est survenue, veuillez réessayer.');
        }
    };

    return (
        <>
            <Navbar />
            <div className="form-container">
                <div className="form-reset-content">
                    <form className='form' onSubmit={handleSubmit}>
                        <h1>Mot de passe oublié ?</h1>
                        <p className='form-reset-text'>Veuillez saisir l'adresse email utilisée dans votre compte client pour recevoir un lien de réinitialisation de mot de passe.</p>
                        <label className="label">
                            Votre email:
                            <input className="input" type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                        </label>
                        <button className="button" type="submit">Envoyer le lien de réinitialisation</button>
                        {message && <p className='form-message'>{message}</p>}
                    </form>
                </div>

            </div>
            <Footer />
        </>
    );
};

export default ForgotPassword;
