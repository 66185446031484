import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BASE_URL } from '../../../config/config';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

const ProductAddAdmin = () => {

    const [nameProduct, setNameProduct] = useState("");
    const [descriptionProduct, setDescriptionProduct] = useState("");
    const [priceProduct, setPriceProduct] = useState("");
    const [stock, setStock] = useState("");
    const [category_id, setCategoryId] = useState("");
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        getCategories();
    }, []);

    const getCategories = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/categories`);
            setCategories(response.data)
        } catch (error) {
            console.error('Erreur lors de la récupération des catégories', error);
        }
    };

    const handleCategory = (event) => {
        setCategoryId(event.target.value);
    }

    const addProduct = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append("nameProduct", nameProduct);
        formData.append("descriptionProduct", descriptionProduct);
        formData.append("priceProduct", priceProduct * 100);
        formData.append("stock", stock);
        formData.append("category_id", category_id);
        try {
            const response = await axios.post(`${BASE_URL}/products`, formData, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                }
            });
            toast.success(response.data.message);
            setNameProduct("");
            setDescriptionProduct("");
            setPriceProduct("");
            setStock("");
            setCategoryId("");
        } catch (error) {
            console.error('Error adding product', error);
            if (error.response && error.response.data) {
                toast.warn(error.response);
            }
        }
    }

    return (
        <>
            <div className="admin-form-container">
                <form className='admin-form' onSubmit={addProduct}>
                    <h1>Ajouter un produit</h1>
                    <label className="label">
                        Nom du produit
                        <input
                            className='input'
                            type="text"
                            name="nameProduct"
                            value={nameProduct}
                            onChange={(event) => {
                                setNameProduct(event.target.value)
                            }}
                        />
                    </label>
                    <label className='label'>
                        Description
                        <textarea
                            cols="30"
                            rows="6"
                            className='input'
                            name="descriptionProduct"
                            value={descriptionProduct}
                            onChange={(event) => {
                                setDescriptionProduct(event.target.value)
                            }}
                        />
                    </label>
                    <label className="label">
                        Prix à l'unité
                        <input
                            className='input'
                            type="text"
                            name="priceProduct"
                            value={priceProduct}
                            onChange={(event) => {
                                setPriceProduct(event.target.value)
                            }}
                        />
                    </label>

                    <label className="label">
                        Quantité en stock
                        <input
                            className='input'
                            type="text"
                            name="stock"
                            value={stock}
                            onChange={(event) => {
                                setStock(event.target.value)
                            }}
                        />
                    </label>
                    <label className='label'>Catégorie</label>
                    <select onChange={handleCategory} value={category_id}>
                        <option>Choisissez une catégorie</option>
                        {categories.map(category => (
                            <option
                                key={category.id}
                                value={category.id}>
                                {category.nameCategory}
                            </option>
                        ))}
                    </select>
                    <div className="admin-form-bot">
                        <button className='admin-form-btn' type="submit">Sauvegarder</button>
                        <button className='admin-form-btn-cancel'>
                            <Link to="/admin/produits/liste" >
                                Annuler
                            </Link>
                        </button>
                    </div>
                </form>
            </div>
        </>
    );
};

export default ProductAddAdmin;