import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BASE_URL } from '../../../config/config';
import { Link } from 'react-router-dom';
import CategoryAddAdmin from './categoryAddAdmin';
import { toast } from 'react-toastify';
import Loader from '../../loader/loader';

const CategoriesListAdmin = () => {
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        displayCategories();
    }, [])

    const displayCategories = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/categories`);
            setCategories(response.data);
            setLoading(true);
        } catch (error) {
            console.error('Erreur lors de la récupération des catégories', error);
        }
    }

    const deleteCategory = async (id) => {
        if (!window.confirm('Êtes-vous sûr de vouloir supprimer cette catégorie ?')) {
            return
        }
        try {
            const response = await axios.delete(`${BASE_URL}/categories/${id}`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                }
            });
            displayCategories();
            toast.success(response.data.message);
        } catch (error) {
            console.error('Erreur', error);
        }
    }

    const handleCategoryAdded = () => {
        displayCategories();
    };

    return (
        <>
            {loading
                ? <div className="admin-category-container">
                    <div className="table-container">
                        <h1>Liste des catégories</h1>
                        <table className='admin-table'>
                            <thead>
                                <tr>
                                    <th>Nom</th>
                                    <th>Supprimer</th>
                                    <th>Modifier</th>
                                </tr>
                            </thead>
                            <tbody>
                                {categories && categories.map((category) => (
                                    <tr key={category.id} >
                                        <td>{category.nameCategory}</td>
                                        <td>
                                            <div className="admin-btn-delete" onClick={() => deleteCategory(category.id)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" height="22" width="18" viewBox="0 0 448 512"><path fill="#BC0B0B" d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z" /></svg>
                                            </div>
                                        </td>
                                        <td>
                                            <Link to={`/admin/categorie/modifier/${category.id}`} >
                                                <div className="admin-btn">
                                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="20" viewBox="0 0 512 512"><path fill="#008000" d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" /></svg>
                                                </div>
                                            </Link>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <CategoryAddAdmin onCategoryAdded={handleCategoryAdded} />
                </div>
                : <Loader />
            }
        </>
    );
};

export default CategoriesListAdmin;