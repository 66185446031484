import React, { useEffect, useState } from 'react';
import Navbar from '../navbar/navbar';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { BASE_IMAGE, BASE_URL } from '../../config/config';
import Cart from '../../services/cart/cart';
import { toast } from 'react-toastify';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Footer from '../footer/footer';
import Loader from '../loader/loader';

const ProductDetails = () => {

    const { id } = useParams();
    const [product, setProduct] = useState({});
    let cart = new Cart();
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        const fetchProduct = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/products/${id}`);
                setProduct(response.data);
                setLoading(true);
            } catch (error) {
                console.error('Erreur', error);
            }
        }
        fetchProduct();
    }, [id]);

    const handleAddToCart = (product) => {
        if (1 < product.stock - 1) {
            cart.add(product);
            toast.success("Produit ajouté au panier");
        } else {
            toast.warning("Le stock disponible est insuffisant pour ajouter cette quantité au panier");
        }
    }

    return (
        <>
            <Navbar />
            {loading
                ? <section className="container">
                    {product && (
                        <>
                            <div className="product-details-container">
                                <Carousel
                                    autoPlay
                                    interval={6000}
                                    // infiniteLoop
                                    // thumbWidth={150}
                                    showIndicators={false}
                                    showStatus={false}
                                    // width={500}
                                >
                                    {product.pictures && product.pictures.map((picture, index) => (
                                        <div key={index} className="product-details-pictures">
                                            <img src={`${BASE_IMAGE}/${picture.namePicture}`} alt={picture.alt} />
                                        </div>
                                    ))}
                                </Carousel>
                                <div className="product-details-info">
                                    <div className="product-name">
                                        <h1>{product.nameProduct}</h1>
                                        <span> {product.category && product.category.nameCategory} </span>
                                    </div>
                                    <p> {product.descriptionProduct} </p>
                                    <div className="product-info">
                                        {product.stock <= 5 ? (
                                            <span className='product-stockless'>
                                                <span className="stock-dot stockless"></span> {product.stock} articles en stock
                                            </span>
                                        ) : (
                                            <span className='product-stock' >
                                                <span className="stock-dot"></span> {product.stock} articles en stock
                                            </span>
                                        )}
                                        <span className='product-price' > {product.priceProduct / 100}  <span>€</span> </span>
                                        {product.stock > 0 ? (
                                            <button className="add-to-cart-button" onClick={() => handleAddToCart(product)}>Ajouter au panier</button>
                                        ) : (
                                            <span className="product-out-of-stock">Potentiellement en rupture de stock</span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </section>
                : <Loader />
            }
            <Footer />
        </>
    );
};

export default ProductDetails;