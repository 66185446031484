import axios from 'axios';
import React, { useState } from 'react';
import { BASE_URL } from '../config/config';
import Navbar from '../components/navbar/navbar';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/authContext';
import Footer from '../components/footer/footer';
import Seo from '../components/seo/seo';

const RegisterPage = () => {

    const navigate = useNavigate();
    const [lastName, setLastName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { login } = useAuth();
    const [checkbox, setCheckbox] = useState(false);

    const handleRegister = async (event) => {
        event.preventDefault();

        const trimmedLastName = lastName.trim();
        const trimmedFirstName = firstName.trim();
        const trimmedEmail = email.trim();
        const trimmedPassword = password.trim();

        if (!trimmedLastName || !trimmedFirstName || !trimmedEmail || !trimmedPassword) {
            toast.error("Veuillez remplir tous les champs.");
            return;
        }

        if (trimmedLastName.length < 2 || trimmedFirstName.length < 2) {
            toast.error("Les noms doivent contenir au moins 2 caractères.");
            return;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(trimmedEmail)) {
            toast.error("Veuillez saisir une adresse e-mail valide.");
            return;
        }

        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>]).*$/;
        if (!passwordRegex.test(trimmedPassword)) {
            toast.error("Le mot de passe doit contenir au moins une lettre minuscule, une lettre majuscule, un chiffre et un caractère spécial.");
            return;
        }

        if (trimmedPassword.length < 8) {
            toast.error("Le mot de passe doit contenir au moins 8 caractères.");
            return;
        }

        if (!checkbox) {
            toast.error("Veuillez accepter notre politique de confidentialité en cochant la case.");
            return;
        }

        const formData = new FormData();
        formData.append("lastName", trimmedLastName);
        formData.append("firstName", trimmedFirstName);
        formData.append("email", trimmedEmail);
        formData.append("password", trimmedPassword);

        try {
            const response = await axios.post(`${BASE_URL}/register`, formData)

            if (response.data.data.access_token.token) {
                login(response.data.data.access_token.token);
                toast.success(response.data.meta.message);
                const userFirstName = `${response.data.data.user.firstName}`
                toast.success(`Bienvenue ${userFirstName}!`);
                navigate("/accueil");
                // Nettoyage des champs du formulaire après succès
                setLastName("");
                setFirstName("");
                setEmail("");
                setPassword("");
                setCheckbox(false);
            } else {
                console.error("Token non trouvé dans la réponse.");
            }

        } catch (error) {
            if (error.response) {
                console.error("Erreur lors de l'inscription", error.response.data);
                toast.error("Erreur lors de l'inscription: " + (error.response.data.message || "Détails non disponibles"));
            } else {
                console.error("Erreur lors de l'inscription", error.message);
                toast.error("Erreur lors de l'inscription");
            }
        }
    }

    const handleCheckboxChange = () => {
        setCheckbox(!checkbox);
    };

    return (
        <>
            <Seo title="Inscription - arcadedojo.fr" description="Inscrivez-vous sur notre site web. | arcadedojo.fr" />
            <Navbar />
            <div className='form-container'>
                <div className="form-content">
                    <div className='form-aside'>
                        <img src='/pictures/temple.jpg' alt='Temple Japonais' />
                    </div>
                    <form className='form' onSubmit={handleRegister}>
                        <h1>création de compte</h1>
                        <h2>vos identifiants :</h2>
                        <div>
                            <label className='label'>E-mail:
                                <input className='input' type='email' value={email} onChange={(event) => setEmail(event.target.value)} />
                            </label>
                        </div>
                        <div>
                            <label className='label'>Mot de passe:
                                <input className='input' type='password' value={password} onChange={(event) => setPassword(event.target.value)} />
                            </label>
                        </div>
                        <h2>vos informations personnelles :</h2>
                        <div>
                            <label className='label'>Prénom:
                                <input className='input' type='text' value={firstName} onChange={(event) => setFirstName(event.target.value)} />
                            </label>
                        </div>
                        <div>
                            <label className='label'>Nom de famille:
                                <input className='input' type='text' value={lastName} onChange={(event) => setLastName(event.target.value)} />
                            </label>
                        </div>
                        <div className='checkbox'>
                            <label className='label-checkbox'>
                                <input className='input-checkbox' type="checkbox" onChange={handleCheckboxChange} />
                                Veuillez accepter notre politique de confidentialité en cochant la case.
                            </label>
                        </div>
                        <button className='button' type="submit">Valider</button>
                    </form>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default RegisterPage;