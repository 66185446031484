import React, { useState } from 'react';
import Navbar from '../components/navbar/navbar';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../config/config';
import { toast } from 'react-toastify';
import { useAuth } from '../contexts/authContext';
import Footer from '../components/footer/footer';
import Seo from '../components/seo/seo';

const LoginPage = () => {

    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { login } = useAuth();

    const handleConnect = async (event) => {
        event.preventDefault();

        if (!email || !password) {
            toast.error("Veuillez remplir tous les champs.");
            return;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            toast.error("Veuillez saisir une adresse e-mail valide.");
            return;
        }

        const formData = new FormData();
        formData.append("email", email);
        formData.append("password", password);

        try {
            const response = await axios.post(`${BASE_URL}/login`, formData)
            if (response.data && response.data.data.access_token.token) {
                const userRoles = response.data.data.user.role;
                login(response.data.data.access_token.token, userRoles);
                toast.success(response.data.meta.message);
                const userFirstName = `${response.data.data.user.firstName}`
                toast.success(`Bonjour ${userFirstName}!`);

                if (userRoles === "[ROLE_ADMIN]") {
                    navigate("/admin");
                } else {
                    navigate("/accueil");
                }

            } else {
                console.error("Token non trouvé dans la réponse.");
                toast.error("Erreur lors de la connexion");
            }

        } catch (error) {
            toast.error("Erreur lors de la connexion: " + (error.response?.data?.message || error.message));
            console.error("Erreur lors de la connexion: ", error.message);
        }
    }

    return (
        <>
            <Seo title="Connexion - arcadedojo.fr" description="Connectez-vous sur notre site web. | arcadedojo.fr" />
            <Navbar />
            <div className="form-container">
                <div className="form-content">
                    <div className="form-aside">
                        <img src='/pictures/sakura.jpg' alt='Sakura en fleurs' />
                    </div>
                    <form className="form" onSubmit={handleConnect}>
                        <h1>Déjà client ?</h1>
                        <div>
                            <label className="label">Email:
                                <input className="input" value={email} onChange={e => setEmail(e.target.value)} type="email" />
                            </label>
                        </div>
                        <div>
                            <label className="label">Mot de passe:
                                <input className="input" value={password} onChange={e => setPassword(e.target.value)} type="password" />
                            </label>
                        </div>
                        <button className="button" type="submit">Connexion</button>
                        <Link className='form-forgot-password-btn' to={'/forgot-password'} >
                            Mot de passe oublié ?
                        </Link>
                        <div className='sub-box'>
                            <p>nouveau client ?</p>
                            <Link className='sub-button' to={'/inscription'}>
                                créez un compte
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default LoginPage;