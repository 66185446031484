import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BASE_URL } from '../../../config/config';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../loader/loader';

const OrderDetailAdmin = () => {

    const { id } = useParams();
    const [order, setOrder] = useState([]);
    const [products, setProducts] = useState([]);
    const [checkout, setCheckout] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const displayOrder = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/orders/${id}`, {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    }
                });
                setOrder(response.data.data.orderData);
                setProducts(response.data.data.orderData.products);
                setCheckout(response.data.data.sessionData);
                setLoading(true);
            } catch (error) {
                console.error('Erreur lors de la récupération des commandes', error);
            }
        }
        displayOrder();
    }, [id]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        };
        return date.toLocaleDateString('fr-FR', options);
    }

    const back = () => {
        navigate(-1);
        return;
    }

    return (
        <>
            {loading
                ? <div className='admin-order-container'>
                    <div className='order-title-box'>
                        <h1>Détails de la commande</h1>
                        <button className='order-back-btn' onClick={back} >Retour</button>
                    </div>

                    {order && (
                        <div className='order-details'>
                            <div className='order-box-details'>
                                <h2>Commande N° {order.id}</h2>
                                <table className='order-table-details'>
                                    <tbody>
                                        <tr>
                                            <th>Date:</th>
                                            <td>{formatDate(order.created_at)}</td>
                                        </tr>
                                        <tr>
                                            <th>N° commande:</th>
                                            <td>{order.reference}</td>
                                        </tr>
                                        <tr>
                                            <th>Montant Total:</th>
                                            <td>{order.totalAmount / 100} €</td>
                                        </tr>
                                        <tr>
                                            <th>Statut de la commande:</th>
                                            <td className='td-status'>
                                                {order.paymentStatus === 'paid' && (
                                                    "Payée"
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            {order.user && (
                                <div className='order-box-client'>
                                    <h3>- Clients -</h3>
                                    <table className='order-table-client'>
                                        <tbody>
                                            <tr>
                                                <th>Nom:</th>
                                                <td>{order.user.lastName}</td>
                                            </tr>
                                            <tr>
                                                <th>Prénom:</th>
                                                <td>{order.user.firstName}</td>
                                            </tr>
                                            <tr>
                                                <th>Email:</th>
                                                <td>{order.user.email}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>
                    )}
                    {products && (
                        <div className="order-products">
                            <table className='order-table-products'>
                                <tbody>
                                    {products.map((product, index) => (
                                        <tr key={index}>
                                            <td>{product.nameProduct}</td>
                                            <td>x {product.pivot.quantity}</td>
                                            <td>{product.pivot.price / 100} €</td>
                                            <td>{product.pivot.quantity * product.pivot.price / 100} €</td>
                                        </tr>
                                    ))}
                                </tbody>
                                {order && (
                                    <tfoot>
                                        <tr>
                                            <td className='td-tfoot'></td>
                                            <td className='td-tfoot' colSpan="2">Frais de port :</td>
                                            <td className='td-tfoot'>{order.shippingAmount / 100} €</td>
                                        </tr>
                                        <tr >
                                            <td className='td-tfoot'>
                                                {order.shippingAmount === 500 ? (
                                                    'Mode de livraison : Standard'
                                                ) : (
                                                    'Mode de livraison : Express'
                                                )}
                                            </td>
                                            <td className='tfoot' colSpan="2">
                                                Total de la commande :
                                            </td>
                                            <td className='tfoot'>{order.totalAmount / 100} €</td>
                                        </tr>
                                    </tfoot>
                                )}
                            </table>
                        </div>
                    )}
                    {checkout && (
                        <div className='order-address'>
                            {checkout.shipping_details && (
                                <ul>
                                    <li><h3>Adresse de livraison</h3></li>
                                    <li>{checkout.shipping_details.address.line1}</li>
                                    <li>{checkout.shipping_details.address.line2}</li>
                                    <li>{checkout.shipping_details.address.postal_code}</li>
                                    <li>{checkout.shipping_details.address.city}</li>
                                    <li>{checkout.shipping_details.address.country}</li>
                                </ul>
                            )}
                            {checkout.customer_details && order.user && (
                                <ul>
                                    <li><h3>Adresse de facturation</h3></li>
                                    <li>{checkout.customer_details.address.line1}</li>
                                    <li>{checkout.customer_details.address.line2}</li>
                                    <li>{checkout.customer_details.address.postal_code}</li>
                                    <li>{checkout.customer_details.address.city}</li>
                                    <li>{checkout.customer_details.address.country}</li>
                                </ul>
                            )}
                        </div>
                    )}
                </div>
                : <Loader />
            }
        </>
    );
};

export default OrderDetailAdmin;