import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Navbar from '../components/navbar/navbar';
import Footer from '../components/footer/footer';
import { BASE_URL } from '../config/config';
import { toast } from 'react-toastify';

const ResetPassword = () => {
    const { token } = useParams();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>]).*$/;
        if (!passwordRegex.test(password)) {
            setMessage("Au moins une lettre minuscule, une lettre majuscule, un chiffre et un caractère spécial.");
            return;
        }

        try {
            const response = await axios.post(`${BASE_URL}/password/reset`, {
                email,
                password,
                password_confirmation: passwordConfirmation,
                token,
            });
            setMessage(response.data.message);
            toast.success(response.data.message);
            navigate('/connexion');
        } catch (error) {
            setMessage('Une erreur est survenue, veuillez réessayer.');
            toast.error('Une erreur est survenue, veuillez réessayer.');
        }
    };

    return (
        <>
            <Navbar />
            <div className="form-container">
                <div className="form-reset-content">
                    <form className="form" onSubmit={handleSubmit}>
                    <h1>Réinitialisation du mot de passe</h1>
                    <label className="label">
                        Email:
                        <input className="input" type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                    </label>
                    <label className="label">
                        Nouveau mot de passe:
                        <input className="input" type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                    </label>
                    <label className="label">
                        Confirmer le mot de passe:
                        <input className="input" type="password" value={passwordConfirmation} onChange={(e) => setPasswordConfirmation(e.target.value)} required />
                    </label>
                    <button className="button" type="submit">Réinitialiser le mot de passe</button>
                    {message && <p className='form-message'>{message}</p>}
                </form>
                </div>
                
            </div>
            <Footer />
        </>

    );
};

export default ResetPassword;
