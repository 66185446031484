import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BASE_URL } from '../config/config';
import Navbar from '../components/navbar/navbar';
import Footer from '../components/footer/footer';
import { toast } from 'react-toastify';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import Seo from '../components/seo/seo';


const Contact = () => {

    const [emailContact, setEmailContact] = useState('');
    const [messageContact, setMessageContact] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const getUserEmailFromJWT = () => {
            const token = localStorage.getItem('token');
            if (token) {
                const decodedToken = jwtDecode(token);
                if (decodedToken && decodedToken.email) {
                    setEmailContact(decodedToken.email);
                }
            }
        };
        getUserEmailFromJWT();
    }, []);

    const handleContact = async () => {

        // Nettoyage des entrées utilisateur
        const trimmedEmail = emailContact.trim();
        const trimmedMessage = messageContact.trim();

        if (!trimmedEmail || !trimmedMessage) {
            toast.error("Veuillez remplir tous les champs.");
            return;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(trimmedEmail)) {
            toast.error("Veuillez saisir une adresse e-mail valide.");
            return;
        }

        if (trimmedMessage.length < 5) {
            toast.error("Le message doit contenir au moins 5 caractères.");
            return;
        }

        const formData = {
            emailContact: trimmedEmail,
            messageContact: trimmedMessage,
        };

        try {
            await axios.post(`${BASE_URL}/contacts`, formData);
            toast.success("Merci pour le message, nous vous répondrons très bientôt.");
            setEmailContact("");
            setMessageContact("");
            navigate("/accueil");

        } catch (error) {
            console.error("Erreur lors de l'envoi du formulaire", error.message);
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(`Erreur: ${error.response.data.message}`);
            } else {
                toast.error("Erreur lors de l'envoi du formulaire");
            }
        }
    }

    return (
        <>
            <Seo title="Contact - arcadedojo.fr" description="Laissez un message sur notre site web ArcadeDojo | arcadedojo.fr" />
            <Navbar />
            <div className='form-container' id='contact'>
                <div className='form-content'>
                    <div className='form-aside'>
                        <img src='/pictures/joystick.jpg' alt='Temple Japonais' />
                    </div>
                    <div className='form'>
                        <h1>Contactez-nous</h1>
                        <div>
                            <label className='label'>E-mail:</label>
                            <input className='input' type='email' value={emailContact} onChange={(event) => setEmailContact(event.target.value)} />
                        </div>
                        <div>
                            <label className='label'>Votre message:</label>
                            <textarea
                                value={messageContact}
                                onChange={(event) => setMessageContact(event.target.value)}
                                className='form-textarea'
                            />
                        </div>
                        <button className='button' onClick={handleContact}>Envoyer</button>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Contact;