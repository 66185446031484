import axios from 'axios';
import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../config/config';
import { toast } from 'react-toastify';
import { useAuth } from '../../contexts/authContext';

const Logout = () => {

    const navigate = useNavigate();
    const { logout } = useAuth();

    const handleDisconnect = async () => {

        try {
            const token = localStorage.getItem('token');
            const response = await axios.post(`${BASE_URL}/logout`, {
                token: token,
            });

            logout();
            navigate("/accueil");
            toast.success(response.data.meta.message);

        } catch (error) {
            console.error("Erreur lors de la déconnexion: ", error.message);
        }
    }

    return (
        <>
            <NavLink className="navbar-link" onClick={handleDisconnect}>Déconnexion</NavLink>
        </>
    );
};

export default Logout;