import React from 'react';
import Navbar from '../components/navbar/navbar';
import Seo from '../components/seo/seo';
import Footer from '../components/footer/footer';

const PersonalData = () => {
    return (
        <>
            <Seo title="Politique de confidentialité - arcadedojo.fr" description="Politique de confidentialité de notre site web ArcadeDojo" />
            <Navbar />
            <div className='container'>
                <div className="policy-content">
                    <h1>Politique de confidentialité</h1>
                    <h2>Engagement envers la protection des données personnelles</h2>
                    <p>La protection de vos données personnelles est une priorité pour nous. Nous respectons les réglementations en vigueur, y compris le Règlement Général sur la Protection des Données (RGPD), et nous nous engageons à protéger votre vie privée en adoptant des mesures techniques et organisationnelles appropriées pour garantir la sécurité de vos informations.</p>
                    <p>Nous collectons et traitons vos données personnelles de manière transparente et conformément aux lois applicables. Vos informations sont utilisées uniquement pour les finalités expliquées dans cette politique, comme le traitement de vos commandes, l'amélioration de nos services, et la personnalisation de votre expérience d'achat.</p>
                    <p>Nous avons mis en place des politiques rigoureuses pour protéger vos données contre tout accès non autorisé, altération, divulgation ou destruction. Notre équipe est formée et sensibilisée à l'importance de la protection des données personnelles, et nous travaillons continuellement à améliorer nos pratiques en matière de sécurité des données.</p>
                    <p>Pour toute question ou préoccupation concernant notre politique de confidentialité, ou pour exercer vos droits relatifs à vos données personnelles, vous pouvez nous contacter à tout moment via les coordonnées fournies dans cette politique.</p>
                    <p>Nous vous remercions de votre confiance et restons à votre disposition pour toute information complémentaire.</p>
                    <h2>Données collectées</h2>
                    <h3>Informations personnelles</h3>
                    <ul>
                        <li>Nom complet : Utilisé pour identifier et personnaliser votre expérience sur notre site.</li>
                        <li>Adresse postale : Nécessaire pour la livraison des produits commandés.</li>
                        <li>Numéro de téléphone : Permet de vous contacter en cas de besoin (problèmes de livraison, questions sur votre commande).</li>
                        <li>Adresse e-mail : Utilisée pour la confirmation de commande, les notifications de livraison, et les communications marketing si vous avez donné votre consentement.</li>
                    </ul>
                    <h3>Informations de compte</h3>
                    <ul>
                        <li>Identifiant et mot de passe : Utilisés pour créer et sécuriser votre compte utilisateur sur notre site.</li>
                        <li>Historique des commandes : Conserve la trace des produits que vous avez achetés pour faciliter les réclamations et les retours.</li>
                    </ul>
                    <h3>Informations de paiement</h3>
                    <ul>
                        <li>Détails de la carte de crédit/débit : Collectés via une plateforme de paiement sécurisée pour traiter vos transactions.</li>
                        <li>Adresse de facturation : Nécessaire pour le traitement et la vérification des paiements.</li>
                    </ul>
                    <h3>Données transactionnelles</h3>
                    <ul>
                        <li>Historique des achats : Enregistre les produits que vous avez achetés, les montants dépensés, et les dates des transactions.</li>
                        <li>Préférences de produits : Basées sur vos achats antérieurs, afin de vous proposer des recommandations personnalisées.</li>
                    </ul>
                    <h3>Informations techniques</h3>
                    <ul>
                        <li>Adresse IP : Collectée pour des raisons de sécurité et pour mieux comprendre l’utilisation de notre site.</li>
                        <li>Cookies et technologies similaires : Utilisés pour améliorer votre expérience de navigation, personnaliser le contenu, et analyser le trafic sur le site.</li>
                        <li>Données de navigation : Informations sur la manière dont vous naviguez et interagissez avec notre site (pages visitées, temps passé sur le site, liens cliqués).</li>
                    </ul>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default PersonalData;