import React, { useEffect, useState } from 'react';
import axios from "axios";
import { NavLink } from 'react-router-dom';
import { BASE_IMAGE, BASE_URL } from '../../config/config';
import Cart from '../../services/cart/cart';
import { toast } from 'react-toastify';
import Loader from '../loader/loader';

const ProductsList = ({ limit, categories, eventOccurred }) => {

    const [products, setProducts] = useState([]);
    let cart = new Cart();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/products`, {
                    params: {
                        limit: limit,
                        category: categories,
                        page: currentPage,
                    }
                });
                setProducts(response.data.data);
                setTotalPages(response.data.last_page);
                setLoading(true);
            } catch (error) {
                console.error('Erreur lors de la récupération des produits', error);
            }
        }
        fetchProducts();
    }, [currentPage, categories, limit])

    useEffect(() => {
        if (eventOccurred) {
            setCurrentPage(1);
        }
    }, [eventOccurred]);

    const handleAddToCart = (product) => {
        if (1 < product.stock - 1) {
            cart.add(product);
            toast.success("Produit ajouté au panier");
        } else {
            toast.warning("Le stock disponible est insuffisant pour ajouter cette quantité au panier");
        }
    }

    const handlePageChange = (page) => {
        setCurrentPage(page); 
    };

    return (
        <>
            {loading
                ? <div>
                    <div className='products-list-container' >
                        {products.map((product) => (
                            <div key={product.id} className='product-card'>
                                <NavLink key={product.id} to={`/produit/${product.slugProduct}/${product.id}`} >
                                    <div className='product-image'>
                                        {product.pictures && product.pictures.length > 0 && (
                                            <img
                                                width={200}
                                                src={`${BASE_IMAGE}/${product.pictures[0].namePicture}`}
                                                alt={product.pictures[0].alt}
                                            />
                                        )}
                                    </div>
                                    <div className="product-details">
                                        <span>{product.category.nameCategory}</span>
                                        <h4> {product.nameProduct} </h4>
                                        <p>{product.descriptionProduct}</p>
                                    </div>
                                </NavLink>
                                <div className="product-info">
                                    {product.stock <= 5 ? (
                                        <span className='product-stockless'>
                                            <span className="stock-dot stockless"></span> {product.stock} articles en stock
                                        </span>
                                    ) : (
                                        <span className='product-stock' >
                                            <span className="stock-dot"></span> {product.stock} articles en stock
                                        </span>
                                    )}
                                    <span className='product-price' > {product.priceProduct / 100}  <span>€</span> </span>
                                    {product.stock > 0 ? (
                                        <button className="add-to-cart-button" onClick={() => handleAddToCart(product)} >Ajouter au panier</button>
                                    ) : (
                                        <span className="product-out-of-stock">Potentiellement en rupture de stock</span>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="pagination">
                        {currentPage > 1 && (
                            <button onClick={() => handlePageChange(currentPage - 1)}>Précédent</button>
                        )}
                        {Array.from({ length: Math.min(totalPages, 3) }, (_, index) => {
                            const pageOffset = Math.max(1, currentPage - 2);
                            return (
                                <button
                                    key={index}
                                    onClick={() => handlePageChange(pageOffset + index)}
                                    className={pageOffset + index === currentPage ? 'active' : ''}
                                >
                                    {pageOffset + index}
                                </button>
                            )
                        })}
                        {currentPage < totalPages && (
                            <button onClick={() => handlePageChange(currentPage + 1)}>Suivant</button>
                        )}
                    </div>
                </div>
                : <div className='loader-container'><Loader /></div>
            }
        </>
    );
};

export default ProductsList;