import { jwtDecode } from 'jwt-decode';

const getToken = () => {
  return localStorage.getItem("token");
}

const getDecodedToken = () => {
  if (getToken()) {
    return jwtDecode(localStorage.getItem("token"));
  } else {
    return false;
  }
}

const getExpiryTime = () => {
  if (getDecodedToken() && !(getDecodedToken().exp * 1000 < Date.now())) {
    return true;
  } else {
    return localStorage.removeItem('token');
  }
}

const getRoles = () => {
  if (getExpiryTime()) {
    return getDecodedToken().role;
  } else {
    return false;
  }
};

const getName = () => {
  if (getExpiryTime()) {
    return getDecodedToken().firstName;
  } else {
    return false;
  }
};

const getEmail = () => {
  if (getExpiryTime()) {
    return getDecodedToken().email;
  } else {
    return false;
  }
};

const getId = () => {
  if (getExpiryTime()) {
    return getDecodedToken().sub;
  } else {
    return false;
  }
};

const loggedAndAdmin = () => {
  return (getExpiryTime() && getRoles() === "[ROLE_ADMIN]");
};

const authService = {
  getToken,
  getDecodedToken,
  getExpiryTime,
  getRoles,
  getName,
  getEmail,
  getId,
  loggedAndAdmin,
};

export default authService;