import React from 'react';
import Navbar from '../components/navbar/navbar';
import Footer from '../components/footer/footer';
import ProductsList from '../components/products/productsList';
import Seo from '../components/seo/seo';

const Parts = () => {
    return (
        <>
            <Seo title="Composants - arcadedojo.fr" description="Découvrez tous nos composants arcade sur notre site web ! | arcadedojo.fr" />
            <Navbar />
            <div className="container">
                <div className='box-1'>
                    <h1>Composants</h1>
                    <p>Explorez notre gamme de composants de joystick, comprenant des boutons de marques renommées telles que Sanwa et Seimitsu, ainsi que des joysticks de qualité supérieure. Nos composants sont conçus pour offrir une précision et une réactivité maximales, vous permettant de personnaliser votre joystick selon vos préférences de jeu. Que vous recherchiez des boutons lumineux, des joystick à retour de force ou des pièces de rechange de haute qualité, vous trouverez tout ce dont vous avez besoin pour améliorer votre expérience de jeu. Parcourez notre sélection dès maintenant et créez le joystick parfait pour vous !</p>
                </div>
                <ProductsList limit={12} categories={['Composant']} />
            </div>
            <Footer />
        </>
    );
};

export default Parts;