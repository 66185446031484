import React from 'react';
import Navbar from '../components/navbar/navbar';
import Seo from '../components/seo/seo';
import Footer from '../components/footer/footer';

const Notice = () => {
    return (
        <>
            <Seo title="Mentions légales - arcadedojo.fr" description="Mentions légales de notre site web ArcadeDojo | arcadedojo.fr" />
            <Navbar />
            <div className='container'>
                <div className="policy-content">
                    <h1>Mentions légales</h1>
                    <h2>Identité de l'entreprise</h2>
                    <ul>
                        <li>Raison sociale: </li>
                        <li>Nom : DoeEI</li>
                        <li>Prénom : JohnEI</li>
                        <li>Siège social : 13 boulevard de Linz 44000 Nantes</li>
                        <li>Numéro de téléphone : 05 05 05 05 05</li>
                        <li>Adresse e-mail : arcadedojoCompany@mail.com</li>
                    </ul>
                    <h2>Numéro d'immatriculation </h2>
                    <p>XXXXXXX</p>
                    <h2>Contact de l'entreprise</h2>
                    <ul>
                        <li>Adresse e-mail : arcadedojoCompany@mail.com</li>
                        <li>Numéro de téléphone : 05 05 05 05 05</li>
                    </ul>
                    <h2>Numéro d'identification à la TVA</h2>
                    <p>FR 26403554181</p>
                    <h2>Hébergeur du site</h2>
                    <ul>
                        <li>o2switch</li>
                        <li>Chemin des Pardiaux</li>
                        <li>63000 Clermont-Ferrand</li>
                        <li>04 44 44 60 40</li>
                        <li>support@02switch.fr</li>
                    </ul>
                </div>
            </div>
            <Footer />
        </>

    );
};

export default Notice;