import React from 'react';
import Navbar from '../components/navbar/navbar';
import Seo from '../components/seo/seo';
import Footer from '../components/footer/footer';
import { Link } from 'react-router-dom';

const Cgv = () => {
    return (
        <>
            <Seo title="Conditions Générales de Vente - arcadedojo.fr" description="Conditions Générales de Vente de notre site web ArcadeDojo | arcadedojo.fr" />
            <Navbar />
            <div className='container'>
                <div className="policy-content">
                    <h1>Conditions Générales de Vente (CGV)</h1>

                    <p>Bienvenue sur ArcedDojo.fr. Ces Conditions Générales de Vente (CGV) régissent l'ensemble des ventes de produits effectuées sur notre site web, propriété de ArcadeDojo, une société enregistrée sous le numéro XXXXXXXXXX, dont le siège social est situé à 13 boulevard de Linz 44000 Nantes. En passant une commande sur notre site, vous acceptez sans réserve les présentes CGV.</p>
                    <h2>Objet</h2>
                    <p>Les présentes CGV ont pour objet de définir les droits et obligations des parties dans le cadre de la vente en ligne des produits proposés par ArcadeDojo à ses clients.</p>
                    <h2>Commande</h2>
                    <h3>Processus de commande :</h3>
                    <ol>
                        <li>Sélection des produits et ajout au panier.</li>
                        <li>Validation du panier.</li>
                        <li>Identification ou création d'un compte client.</li>
                        <li>Saisie des informations de livraison et de paiement.</li>
                        <li>Vérification et validation de la commande.</li>
                        <li>Confirmation de la commande par e-mail.</li>
                    </ol>
                    <h2>Modification de la commande :</h2>
                    <p>Une fois la commande validée, elle ne peut plus être modifiée. En cas d'erreur, veuillez contacter notre service client rapidement.</p>
                    <h2>Prix</h2>
                    <h3>Prix des produits :</h3>
                    <p>Les prix des produits sont indiqués en Euro toutes taxes comprises (TTC), hors frais de livraison.</p>
                    <h3>Frais de livraison :</h3>
                    <p>Les frais de livraison sont calculés en fonction de la destination et du poids du colis, et sont indiqués avant la validation de la commande.</p>
                    <h3>Paiement</h3>
                    <h4>Modes de paiement acceptés :</h4>
                    <ul>
                        <li>Cartes de crédit (Visa, MasterCard, American Express)</li>
                        <li>PayPal</li>
                    </ul>
                    <h3>Conditions de paiement</h3>
                    <p>Le paiement est exigible immédiatement à la commande. Les produits demeurent la propriété de ArcadeDojo jusqu'au complet paiement du prix.</p>

                    <h3>Livraison</h3>
                    <h4>Zones de livraison</h4>
                    <p>Nous livrons nos produits à l'international. Les frais et délais de livraison varient selon la destination.</p>

                    <h4>Délais de livraison</h4>
                    <p>Les délais de livraison varient entre 3 et 10 jours ouvrables, selon la destination et le mode de livraison choisi.</p>

                    <h4>Problèmes de livraison</h4>
                    <p>En cas de retard de livraison, nous vous informerons dès que possible et nous nous efforcerons de résoudre le problème rapidement.</p>

                    <h3>Rétractation et retour</h3>
                    <h4>Droit de rétractation</h4>
                    <p>Conformément à la législation en vigueur, vous disposez d'un délai de 14 jours à compter de la réception de votre commande pour exercer votre droit de rétractation sans avoir à justifier de motifs ni à payer de pénalités.</p>

                    <h4>Procédure de retour</h4>
                    <p>Pour exercer votre droit de rétractation, contactez notre service client à arcadedojoCompany@mail.com. Les produits doivent être retournés dans leur état et emballage d'origine. Les frais de retour sont à votre charge.</p>

                    <h4>Remboursement</h4>
                    <p>Le remboursement sera effectué dans un délai de 14 jours suivant la réception des produits retournés.</p>

                    <h3>Garantie et service après-vente</h3>
                    <h4>Garantie légale</h4>
                    <p>Tous nos produits bénéficient de la garantie légale de conformité et de la garantie contre les vices cachés, conformément aux dispositions légales.</p>

                    <h4>Service après-vente</h4>
                    <p>En cas de problème avec un produit acheté sur notre site, veuillez contacter notre service après-vente à arcadedojoCompany@mail.com. Nous vous fournirons une assistance pour résoudre votre problème dans les plus brefs délais.</p>

                    <h3>Responsabilité</h3>
                    <h4>Limitation de responsabilité</h4>
                    <p>ArcedDojo ne peut être tenu responsable des dommages résultant d'une mauvaise utilisation du produit acheté. Notre responsabilité ne pourra être engagée pour tous les inconvénients ou dommages inhérents à l'utilisation du réseau Internet, notamment une rupture de service, une intrusion extérieure ou la présence de virus informatiques.</p>

                    <h3>Droit applicable et juridiction compétente</h3>
                    <h4>Loi applicable</h4>
                    <p>Les présentes CGV sont soumises à la loi française.</p>

                    <h4>Juridiction compétente</h4>
                    <p>En cas de litige, les tribunaux de Nantes seront seuls compétents, sauf disposition impérative contraire.</p>

                    <h3>Modifications des CGV</h3>
                    <h4>Mise à jour des CGV</h4>
                    <p>Nous nous réservons le droit de modifier les présentes CGV à tout moment. Les nouvelles conditions générales de vente seront applicables dès leur publication sur notre site.</p>

                    <h3>Contact</h3>
                    <h4>Coordonnées de l'entreprise</h4>
                    <p>Pour toute question ou information concernant ces CGV, vous pouvez nous contacter :</p>
                    <ul>
                        <li>ArcadeDojo</li>
                        <li>13 boulevard de Linz 44000 Nantes</li>
                        <li>Numéro de téléphone : 05 05 05 05 05</li>
                        <li>Adresse e-mail : arcadedojoCompany@mail.com</li>
                        <li>
                            <Link to={'/contact#contact'} >
                                Nous contacter
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Cgv;