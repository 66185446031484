import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Logout from '../logout/logout';
import { useAuth } from '../../contexts/authContext';

const Navbar = () => {

    const [showLinks, setShowLinks] = useState(false);
    const { isConnected, roles } = useAuth();

    const handleShowLinks = () => {
        setShowLinks(!showLinks)
    }

    return (
        <nav className={`navbar ${showLinks ? "show-nav" : "hide-nav"}`}>
            <div className="navbar-logo">
                <img src="/pictures/arcade-logo.jpg" alt="logo" />
                <NavLink to="/accueil" >
                    <p className='navbar-title'>ArcadeDojo</p>
                </NavLink>
            </div>
            <ul className="navbar-links">
                <li id="activelink" className="navbar-item slideInDown-1">
                    <NavLink to="/accueil" className="navbar-link" >Accueil</NavLink>
                </li>
                <li id="activelink" className="navbar-item slideInDown-2">
                    <NavLink to="/joysticks" className="navbar-link" >Joysticks</NavLink>
                </li>
                <li id="activelink" className="navbar-item slideInDown-3">
                    <NavLink to="/composants" className="navbar-link" >Composants</NavLink>
                </li>
                <li id="activelink" className="navbar-item slideInDown-4">
                    <NavLink to="/panier" className="navbar-link" >Panier</NavLink>
                </li>
                {isConnected ? (
                    <>
                        <li id="activelink" className="navbar-item slideInDown-4">
                            <NavLink to="/profile" className="navbar-link" >Mon compte</NavLink>
                        </li>
                        <li className='logout navbar-item slideInDown-5' ><Logout /></li>
                    </>
                ) : (
                    <>
                        <li id="activelink" className="navbar-item slideInDown-6">
                            <NavLink to="/connexion" className="navbar-link" >Connexion</NavLink>
                        </li>
                    </>
                )}
                {isConnected && roles === "[ROLE_ADMIN]" && (
                    <li className="navbar-item slideInDown-6">
                        <NavLink to="/admin" className="navbar-link">Admin</NavLink>
                    </li>
                )}
            </ul>
            <button className="navbar-burger" onClick={handleShowLinks}>
                <span className="burger-bar" ></span>
            </button>
        </nav>
    );
};

export default Navbar;