import React, { Fragment } from 'react';
import { Navigate } from "react-router-dom";
import { useAuth } from "../../contexts/authContext";
import Noaccess from "../../pages/Noaccess";

const ProtectedRoute = ({ children, ...rest }) => {
    const { isConnected, roles, isLoaded } = useAuth();

    if (!isLoaded) {
        return null;
    }

    if (!isConnected) {
        return <Navigate to="/connexion" />;
    }

    if (roles !== "[ROLE_ADMIN]") {
        return <Noaccess />;
    }

    return <Fragment {...rest} >{children}</Fragment>
};

export default ProtectedRoute;