import React from 'react';
import Navbar from '../components/navbar/navbar';
import ProductsList from '../components/products/productsList';
import Seo from '../components/seo/seo';
import Footer from '../components/footer/footer';

const Home = () => {

    return (
        <>
            <Seo title="Accueil - arcadedojo.fr" description="Découvrez l'univers d'ArcadeDojo : joysticks, arcade, et bien plus encore sur notre site web ! | arcadedojo.fr" />
            <Navbar />
            <div className="banner">
                <h1 className='home-title'>Boutique de Joysticks Arcade</h1>
                <p>Joysticks, Leverless et Hitbox</p>
            </div>
            <div className="container">
                <div className="box-1">
                    <h2>Découvrez ici l'univers des Joysticks</h2>
                    <p>Explorez notre sélection de joysticks et plongez-vous dans un monde de contrôle ultime. Que vous soyez un joueur occasionnel ou un passionné invétéré, nous avons le joystick parfait pour vous. Des designs ergonomiques aux fonctionnalités avancées, nos produits vous offrent une expérience de jeu inégalée. Parcourez notre collection dès maintenant et trouvez le joystick qui rendra votre expérience de jeu encore plus excitante !</p>
                </div>
                <div className="best-products">
                    <h3>Nos derniers Joysticks</h3>
                    <ProductsList limit={4} categories={['Leverless', 'Joystick', 'Flatbox']} />
                </div>
                <div className="box-1">
                    <h2>Découvrez nos Composants</h2>
                    <p>Explorez notre gamme de composants de joystick, comprenant des boutons de marques renommées telles que Sanwa et Seimitsu, ainsi que des joysticks de qualité supérieure. Nos composants sont conçus pour offrir une précision et une réactivité maximales, vous permettant de personnaliser votre joystick selon vos préférences de jeu. Que vous recherchiez des boutons lumineux, des joystick à retour de force ou des pièces de rechange de haute qualité, vous trouverez tout ce dont vous avez besoin pour améliorer votre expérience de jeu. Parcourez notre sélection dès maintenant et créez le joystick parfait pour vous !</p>
                </div>
                <div className="last-products">
                    <h3>Nos Composants</h3> 
                    <ProductsList limit={4} categories={['Composant']} />
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Home;