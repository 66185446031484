import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BASE_URL } from '../../../config/config';
import { toast } from 'react-toastify';
import Loader from '../../loader/loader';

const ContactsListAdmin = () => {

    const [contacts, setContacts] = useState([]);
    const [selectedContact, setSelectedContact] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        displayContacts();
    }, [])

    const displayContacts = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/contacts`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                }
            });
            setContacts(response.data);
            setLoading(true);
        } catch (error) {
            console.error('Erreur lors de la récupération des contacts', error);
        }
    }

    const deleteContact = async (id) => {
        if (!window.confirm('Êtes-vous sûr de vouloir supprimer ce contact ?')) {
            return
        }
        try {
            const response = await axios.delete(`${BASE_URL}/contacts/${id}`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                }
            });
            displayContacts();
            handleCloseMessage();
            toast.success(response.data.message);
        } catch (error) {
            console.error('Erreur lors de la suppression du contact', error);
        }
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        };
        return date.toLocaleDateString('fr-FR', options);
    }

    const handleViewMessage = (contact) => {
        setSelectedContact(contact);
    }

    const handleCloseMessage = () => {
        setSelectedContact(null);
    }

    const handleReply = (email) => {
        const mailtoLink = `mailto:${email}`;
        window.open(mailtoLink, "_blank");
    }

    return (
        <>
            {loading
                ? <div className="admin-contact-container">
                    {selectedContact ? (
                        <div className="message-dialog">
                            <div className="message-dialog-content">
                                <div className='message-title'>
                                    <h2>Message n° {selectedContact.id}</h2>
                                    <button className="close-button" onClick={handleCloseMessage}>Fermer</button>
                                </div>
                                <p className='message-email'>{selectedContact.emailContact}</p>
                                <p>{selectedContact.messageContact}</p>
                                <div className="message-delete">
                                    <button className='delete-btn' onClick={() => deleteContact(selectedContact.id)}>Supprimer</button>
                                    <button className='answer-btn' onClick={() => handleReply(selectedContact.emailContact)}>Répondre</button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="table-container">
                            <h1>Liste des contacts</h1>
                            <table className="admin-table">
                                <thead>
                                    <tr>
                                        <th>N°</th>
                                        <th>Date</th>
                                        <th>Email</th>
                                        <th>Message</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {contacts && contacts.map((contact) => (
                                        <tr key={contact.id}>
                                            <td>{contact.id}</td>
                                            <td>{formatDate(contact.created_at)}</td>
                                            <td>{contact.emailContact}</td>
                                            <td>{contact.messageContact}</td>
                                            <td><button className='contact-btn' onClick={() => handleViewMessage(contact)}>Voir le message</button></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
                : <Loader />
            }
        </>
    );
};

export default ContactsListAdmin;