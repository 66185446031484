import React, { useEffect, useState } from 'react';
import Navbar from '../navbar/navbar';
import Cart from '../../services/cart/cart';
import axios from 'axios';
import { BASE_IMAGE, BASE_URL } from '../../config/config';
import token from '../../services/auth/token';
import Seo from '../seo/seo';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../footer/footer';

const ShoppingCart = () => {

    const navigate = useNavigate();
    // State pour stocker le contenu du panier
    const [cart, setCart] = useState([]);
    // Instance du service de panier
    const cartService = new Cart();
    // Récupération de l'ID de l'utilisateur
    const userId = token.getId();

    // useEffect pour charger le panier depuis le localStorage lors du montage du composant
    useEffect(() => {
        const storedCart = cartService.cart;
        setCart(storedCart);
        // eslint-disable-next-line
    }, []);

    // Fonction pour augmenter la quantité d'un produit dans le panier
    const handleIncreaseQuantity = (product) => {
        if (product.quantity < product.stock) {
            cartService.add(product);
            setCart([...cartService.cart]);
        } else {
            toast.warning("Le stock disponible est insuffisant pour ajouter cette quantité au panier");
        }
    }

    // Fonction pour diminuer la quantité d'un produit dans le panier
    const handleDecreaseQuantity = (product) => {
        cartService.changeQuantity(product, -1);
        setCart([...cartService.cart]);
    }

    // Fonction pour supprimer un produit du panier
    const handleRemoveFromCart = (product) => {
        cartService.remove(product);
        setCart([...cartService.cart]);
    }

    // Fonction pour vider complètement le panier
    const handleClearCart = () => {
        cartService.deleteCart();
        setCart([]);
    }

    // Fonction pour créer une session de paiement Checkout sur Stripe
    const createCheckoutSessionOn = async (orderId, userId) => {
        try {
            const response = await axios.post(`${BASE_URL}/create-checkout-session`, {
                orderId,
                userId,
            });
            // Mise à jour de la commande avec l'ID de la session Checkout
            await updateOrderWithCheckoutSession(orderId, response.data.sessionId);
            // Redirection vers la page de paiement Stripe
            window.location.href = response.data.location;
        } catch (error) {
            console.error('Erreur lors de la création de la session de paiement:', error);
        }
    };

    // Fonction pour passer la commande
    const handlePlaceOrder = async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/connexion');
            toast.warning('Vous devez être connecté(e) pour passer commande');
            return;
        }
        try {
            const response = await axios.post(`${BASE_URL}/orders`,
                {
                    user_id: userId,
                    products: JSON.stringify(cart),
                },
                {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                }
            );
            const orderId = response.data.data.id;
            // Création de la session de paiement pour la commande
            createCheckoutSessionOn(orderId, userId);
            handleClearCart();
        } catch (error) {
            console.error('Erreur lors de la création de la commande:', error);
        }
    };

    // Mise à jour de la commande avec l'ID de la session Checkout
    const updateOrderWithCheckoutSession = async (orderId, cSessionId) => {
        try {
            await axios.put(`${BASE_URL}/orders/${orderId}`,
                {
                    checkoutSessionId: cSessionId,
                },
                {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                }
            );
            // console.log('Commande mise à jour avec checkoutSessionId:', cSessionId);
        } catch (error) {
            console.error('Erreur lors de la mise à jour de la commande avec checkoutSessionId:', error);
        }
    };

    return (
        <>
            <Seo title="Mon panier - arcadedojo.fr" description="Achetez des joysticks et autres articles sur notre site web." />
            <Navbar />
            <div className='cart-container'>
                <div className='cart-table-container'>
                    <h2>Votre Panier :</h2>
                    <table className='cart-table'>
                        <thead>
                            <tr>
                                <th colSpan={2}>Désignation</th>
                                <th>Prix</th>
                                <th>Quantité</th>
                                <th>Sous-total</th>
                                <th>
                                    {cart.length > 0 && (
                                        <div className='cart-empty' onClick={handleClearCart}>
                                            vider
                                        </div>
                                    )}
                                </th>
                            </tr>
                        </thead>
                        {cart.length > 0 ? (
                            <tbody>
                                {cart.map((product, index) => (
                                    <tr key={index}>
                                        <td className='td-prod-img'>
                                            {product.pictures && product.pictures.length > 0 ? (
                                                <img className='cart-img' src={`${BASE_IMAGE}/${product.pictures[0].namePicture}`}
                                                    alt={product.pictures[0].alt} />
                                            ) : (
                                                <p>Not picture available</p>
                                            )}
                                        </td>
                                        <td className='td-prod '>{product.nameProduct}</td>
                                        <td className='td-prod'>{product.priceProduct / 100} €</td>
                                        <td className='td-prod'>{product.quantity}</td>
                                        <td className='td-prod'>{product.quantity * product.priceProduct / 100} €</td>
                                        <td className='td-prod td-btn'>
                                            <div onClick={() => handleIncreaseQuantity(product)}>
                                                <svg className='svg-cart' xmlns="http://www.w3.org/2000/svg" height="1rem" width="1rem" viewBox="0 0 448 512"><path fill="#000000" d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" /></svg>
                                            </div>
                                            <div onClick={() => handleDecreaseQuantity(product, -1)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" height="1rem" width="1rem" viewBox="0 0 448 512"><path fill="#000000" d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z" /></svg>
                                            </div>
                                            <div onClick={() => handleRemoveFromCart(product)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" height="1rem" width="1rem" viewBox="0 0 448 512"><path fill="#000000" d="M170.5 51.6L151.5 80h145l-19-28.4c-1.5-2.2-4-3.6-6.7-3.6H177.1c-2.7 0-5.2 1.3-6.7 3.6zm147-26.6L354.2 80H368h48 8c13.3 0 24 10.7 24 24s-10.7 24-24 24h-8V432c0 44.2-35.8 80-80 80H112c-44.2 0-80-35.8-80-80V128H24c-13.3 0-24-10.7-24-24S10.7 80 24 80h8H80 93.8l36.7-55.1C140.9 9.4 158.4 0 177.1 0h93.7c18.7 0 36.2 9.4 46.6 24.9zM80 128V432c0 17.7 14.3 32 32 32H336c17.7 0 32-14.3 32-32V128H80zm80 64V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16z" /></svg>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        ) : (
                            <tbody>
                                <tr>
                                    <td colSpan={6}>Votre panier est vide</td>
                                </tr>
                                <tr>
                                    <td colSpan={6}>
                                        <Link to={'/accueil'}>
                                            <button className='btn-back-cart'>Retour à la boutique</button>
                                        </Link>
                                    </td>
                                </tr>
                            </tbody>
                        )}
                        <tfoot>
                            {cart.length > 0 && (
                                <tr>
                                    <td colSpan={4}>Total TTC :</td>
                                    <td>{cartService.getTotalPrice()} €</td>
                                </tr>
                            )}
                        </tfoot>
                    </table>
                    {cart.length > 0 && (
                        <button className='order-button' onClick={handlePlaceOrder}>Passer commande</button>
                    )}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default ShoppingCart;