import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BASE_IMAGE, BASE_URL } from '../../../config/config';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../../loader/loader';

const PicturesAddAdmin = () => {

    const { id } = useParams();
    const [pictures, setPictures] = useState([]);
    const [product, setProduct] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        displayPictures();
        // eslint-disable-next-line
    }, []);

    const displayPictures = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/pictures`);
            const productPictures = response.data.filter(picture => picture.product_id === parseInt(id));
            setPictures(productPictures);
            setLoading(true);
            if (productPictures.length > 0) {
                setProduct(productPictures[0].product.nameProduct);
            }
        } catch (error) {
            console.error('Erreur', error);
        }
    }

    const handlePictureChange = (event) => {
        setSelectedFiles([]);
        if (event.target.files) {
            const filesArray = Array.from(event.target.files).map((file) => URL.createObjectURL(file));
            setSelectedFiles(filesArray);
            Array.from(event.target.files).map(
                (file) => URL.revokeObjectURL(file)
            );
        }
    }

    const renderPhotos = (source) => {
        return source.map((photo) => {
            return <img src={photo} alt="" key={photo} width="150rem" />
        });
    };

    const addPictures = async (event) => {
        event.preventDefault();
        const files = event.target[0].files;

        if (files.length === 0) {
            toast.error('Veuillez sélectionner au moins une image.');
            return;
        }

        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append('namePicture[]', files[i]);
        }

        formData.append('product_id', id);

        try {
            const response = await axios.post(`${BASE_URL}/pictures`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: "Bearer" + localStorage.getItem("token"),
                }
            });
            displayPictures();
            setSelectedFiles([]);
            toast.success(response.data.message);
        } catch (error) {
            console.error('Erreur lors de l\'envoi des images :', error);
            toast.error('Erreur lors de l\'envoi des images.');
        }
    }

    const deletePicture = async (id) => {
        try {
            const response = await axios.delete(`${BASE_URL}/pictures/${id}`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                }
            });
            displayPictures();
            toast.success(response.data.message);
        } catch (error) {
            console.error('Erreur lors de la suppression de l\'image', error);
        }
    }

    return (
        <>
            {loading
                ? <div className="admin-pictures-container">
                    <div className="pictures-form-container">
                        <form className='admin-form' onSubmit={addPictures} encType='multipart/form-data' >
                            {product &&
                                <h1>{product}</h1>}
                            <div className='label-box'>
                                <label className='upload-label' >Ajouter des Images :</label>
                                <input type="file" name='namePicture[]' id="fileInput" className="file-input" multiple onChange={handlePictureChange} />
                                <label htmlFor="fileInput" className="browse-button">Parcourir les images</label>
                            </div>
                            <div className="result">{renderPhotos(selectedFiles)}</div>
                            <div className='admin-form-bot'>
                                <button className='admin-form-btn' type="submit">Enregistrer</button>
                                <Link className='admin-form-btn-cancel' to="/admin/produits/liste" >
                                    Retour
                                </Link>
                            </div>
                        </form>
                    </div>
                    <div className='pictures-container'>
                        <h3>Images du produit</h3>
                        {pictures.map((picture) => (
                            <div className="picture-box" key={picture.id} >
                                <img src={`${BASE_IMAGE}/${picture.namePicture}`} alt="" />
                                <p>{picture.namePicture}</p>
                                <button className='btn-delete-picture' onClick={() => deletePicture(picture.id)} >Supprimer</button>
                            </div>
                        ))}
                    </div>
                </div>
                : <Loader />
            }

        </>
    );
};

export default PicturesAddAdmin;