import React, { Fragment, useEffect, useState } from 'react';
import Navbar from '../components/navbar/navbar';
import token from '../services/auth/token';
import axios from 'axios';
import { BASE_URL } from '../config/config';
import Seo from '../components/seo/seo';
import Footer from '../components/footer/footer';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuth } from '../contexts/authContext';
import Loader from '../components/loader/loader';

const Profile = () => {

    const [user, setUser] = useState(null);
    const [addresses, setAddresses] = useState([]);
    const [orders, setOrders] = useState([]);
    const [ordersDetails, setOrdersDetails] = useState([]);
    const [expandedOrderIds, setExpandedOrderIds] = useState([]);
    const navigate = useNavigate();
    const { logout } = useAuth();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchUser();
    }, []);

    const fetchUser = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/currentuser`, {
                headers: {
                    Authorization: `Bearer ${token.getToken()}`
                }
            });
            setUser(response.data.data.user);
            setAddresses(response.data.data.addresses);
            setOrders(response.data.data.orders);
            setOrdersDetails(response.data.data.ordersDetails);
            setLoading(true);
        } catch (error) {
            console.error('Erreur', error);
        }
    }

    const handleToggleOrderDetails = (orderId) => {
        setExpandedOrderIds(prevState => {
            if (prevState.includes(orderId)) {
                return prevState.filter(id => id !== orderId);
            } else {
                return [...prevState, orderId];
            }
        });
    }

    const calculateOrderTotal = (orderId, shippingAmount) => {
        const orderDetails = ordersDetails.filter(orderDetail => orderDetail.order_id === orderId);
        const productsTotal = orderDetails.reduce((total, orderDetail) => total + (orderDetail.quantity * orderDetail.priceProduct / 100), 0);
        return productsTotal + shippingAmount / 100;
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        };
        return date.toLocaleDateString('fr-FR', options);
    }

    const formatPhoneNumber = (phoneNumber) => {
        const numericPhoneNumber = phoneNumber.replace(/\D/g, '');

        if (numericPhoneNumber.startsWith('33')) {
            return `0${numericPhoneNumber.substring(2, 3)} 
                ${numericPhoneNumber.substring(3, 5)} 
                ${numericPhoneNumber.substring(5, 7)} 
                ${numericPhoneNumber.substring(7, 9)} 
                ${numericPhoneNumber.substring(9)}`;
        }
        return phoneNumber;
    };

    const deleteUserAccount = async () => {
        if (!window.confirm('Êtes-vous sûr de vouloir supprimer votre compte ?')) {
            return
        }
        try {
            if (!user || !user.id || !token || !token.getToken) {
                throw new Error('Informations utilisateur ou token invalides.');
            }
            await axios.delete(`${BASE_URL}/users/${user.id}`, {
                headers: {
                    Authorization: `Bearer ${token.getToken()}`
                }
            });
            logout();
            navigate("/accueil");
            toast.success("Votre compte à été supprimé avec succès.");
        } catch (error) {
            console.error('Erreur lors de la suppression du compte utilisateur :', error);
        }
    }

    return (
        <>
            <Seo title="Mon compte - arcadedojo.fr" description="Gérez votre compte sur notre site web ! | arcadedojo.fr" />
            <Navbar />
            
            <div className="container">
                {loading 
                ? <div className="profile-container">
                    <h1>Mon Compte</h1>
                    <div className="user-content">
                        {user && (
                            <div className="user-info">
                                <h2>Mes informations</h2>

                                <table>
                                    <tbody>
                                        <tr>
                                            <th>Prénom :</th>
                                            <td>{user.firstName}</td>
                                        </tr>
                                        <tr>
                                            <th>Nom :</th>
                                            <td>{user.lastName}</td>
                                        </tr>
                                        <tr>
                                            <th>Email :</th>
                                            <td>{user.email}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <button className='delete-account-btn' onClick={deleteUserAccount}>Supprimer mon compte</button>
                            </div>
                        )}
                        {addresses.length > 0 && (
                            <div className='user-addresses'>
                                <h2>Mes adresses</h2>
                                <div className="user-address-type">
                                    {addresses.map((addresse, index) => (
                                        <ul key={index} >
                                            <li>
                                                <h3>
                                                    {addresse.addressType === 'delivery' ? (
                                                        "Adresse de livraison"
                                                    ) : (
                                                        "Adresse de facturation"
                                                    )}
                                                </h3>
                                            </li>
                                            <li>{addresse.addressLine1}</li>
                                            <li>{addresse.addressLine2}</li>
                                            <li>{addresse.zipcode}</li>
                                            <li>{addresse.city}</li>
                                            <li>Tel: {formatPhoneNumber(addresse.phone)}</li>
                                        </ul>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="orders-content">
                        {orders.length > 0 && (
                            <div className='orders'>
                                <h2>Historique de mes commandes</h2>
                                <table className='profil-table'>
                                    <thead>
                                        <tr>
                                            <th className='th'>Date</th>
                                            <th className='th'>N° commande</th>
                                            <th className='th'>Montant</th>
                                            <th className='th'>Statut</th>
                                            <th className='th'></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orders.map((order, index) => (
                                            <Fragment key={index}>
                                                <tr>
                                                    <td className='td'>{formatDate(order.created_at)}</td>
                                                    <td className='td'>{order.reference}</td>
                                                    <td className='td'>{calculateOrderTotal(order.id, order.shippingAmount)} €</td>
                                                    <td className='td td-status'>
                                                        {order.paymentStatus === 'paid' && (
                                                            "Payée"
                                                        )}
                                                    </td>
                                                    <td className='td'>
                                                        {order.paymentStatus === 'paid' ? (
                                                            <button onClick={() => handleToggleOrderDetails(order.id)}>
                                                                {expandedOrderIds.includes(order.id) ? 'Fermer' : 'Détails'}
                                                            </button>
                                                        ) : (
                                                            "Paiement annulé"
                                                        )}
                                                    </td>
                                                </tr>
                                                {expandedOrderIds.includes(order.id) && (
                                                    <tr>
                                                        <td className='td td-open' colSpan="5">
                                                            <div>
                                                                <table className='details-table'>
                                                                    <tbody>
                                                                        {ordersDetails
                                                                            .filter(orderDetail => orderDetail.order_id === order.id)
                                                                            .map((orderDetail, index) => (
                                                                                <tr key={index}>

                                                                                    <td>{orderDetail.nameProduct}</td>
                                                                                    <td>x {orderDetail.quantity}</td>
                                                                                    <td>{orderDetail.priceProduct / 100} €</td>
                                                                                    <td>{orderDetail.quantity * orderDetail.priceProduct / 100} €</td>
                                                                                </tr>
                                                                            ))}
                                                                    </tbody>
                                                                    <tfoot>
                                                                        <tr>
                                                                            <td className='td-tfoot'></td>
                                                                            <td className='td-tfoot' colSpan="2">Frais de port :</td>
                                                                            <td className='td-tfoot'>{order.shippingAmount / 100} €</td>
                                                                        </tr>
                                                                        <tr >
                                                                            <td className='td-tfoot'>
                                                                                {order.shippingAmount === 500 ? (
                                                                                    'Mode de livraison : Standard'
                                                                                ) : (
                                                                                    'Mode de livraison : Express'
                                                                                )}
                                                                            </td>
                                                                            <td className='tfoot' colSpan="2">
                                                                                Total de la commande :
                                                                            </td>
                                                                            <td className='tfoot'>{calculateOrderTotal(order.id, order.shippingAmount)} €</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='td-address'>
                                                                                <ul>
                                                                                    <li>{order.checkoutSessionShipping.name}</li>
                                                                                    <li>{order.checkoutSessionShipping.address.line1}</li>
                                                                                    <li>{order.checkoutSessionShipping.address.line2}</li>
                                                                                    <li>{order.checkoutSessionShipping.address.postal_code}</li>
                                                                                    <li>{order.checkoutSessionShipping.address.city}</li>
                                                                                </ul>
                                                                            </td>
                                                                            <td>
                                                                                {/* Mode de paiement : Carte bancaire */}
                                                                                {/* <ul>
                                                                                    <li>{order.checkoutSessionShipping.name}</li>
                                                                                    <li>{order.checkoutSessionBilling.address.line1}</li>
                                                                                    <li>{order.checkoutSessionBilling.address.line2}</li>
                                                                                    <li>{order.checkoutSessionBilling.address.postal_code}</li>
                                                                                    <li>{order.checkoutSessionBilling.address.city}</li>
                                                                                </ul> */}
                                                                            </td>
                                                                        </tr>
                                                                    </tfoot>
                                                                </table>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                            </Fragment>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>
                : <div className='loader-container'><Loader /></div>
                }
                
            </div>
            <Footer />
        </>
    );
};

export default Profile;