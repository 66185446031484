import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { BASE_URL } from '../../../config/config';
import axios from 'axios';
import { toast } from 'react-toastify';

const CategoryUpdateAdmin = () => {

    const { id } = useParams();
    const navigate = useNavigate();

    const [nameCategory, setNameCategory] = useState("");

    useEffect(() => {
        const fetchCategory = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/categories/${id}`);
                const categories = response.data;

                if (response.data) {
                    setNameCategory(categories.nameCategory);
                }
            } catch (error) {
                console.error('Erreur lors de la récupération des catégories', error);
            }
        }
        fetchCategory();
    }, [id]);

    const updateCategory = async (event) => {
        event.preventDefault();
        const formData = new FormData();

        formData.append("nameCategory", nameCategory);
        formData.append("_method", "PUT");

        try {
            const response = await axios.post(`${BASE_URL}/categories/${id}`, formData, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                }
            });
            toast.success(response.data.message);
            navigate("/admin/categories/liste");
        } catch (error) {
            console.error('Erreur lors de la mise à jour de la catégorie', error);
            if (error.response && error.response.data) {
                toast.warn(error.response);
            }
        }
    }

    return (
        <>
            <div className="admin-form-container">
                <form className='admin-form' onSubmit={updateCategory} >
                    <h1>Modifier une catégorie</h1>
                    <label className='label'>
                        Nom:
                        <input
                            className='input'
                            type="text"
                            name='nameCategory'
                            value={nameCategory}
                            onChange={(event) => {
                                setNameCategory(event.target.value)
                            }}
                        />
                    </label>
                    <div className='admin-form-bot'>
                        <button className='admin-form-btn' type="submit">Modifier</button>
                        <button className='admin-form-btn-cancel'>
                            <Link to="/admin/categories/liste" >
                                Annuler
                            </Link>
                        </button>
                    </div>
                </form >
            </div >
        </>
    );
};

export default CategoryUpdateAdmin;