import React, { useState } from 'react';
import Navbar from '../components/navbar/navbar';
import Seo from '../components/seo/seo';
import Footer from '../components/footer/footer';
import ProductsList from '../components/products/productsList';

const Arcade = () => {

    const [selectedCategory, setSelectedCategory] = useState('');
    const [eventOccurred, setEventOccurred] = useState(false);

    const handleCategoryChange = (event) => {
        setEventOccurred(true);
        if(event.target.value === "all") {
            setSelectedCategory('');
        } else {
            setSelectedCategory(event.target.value);
        }
    };

    return (
        <>
            <Seo title="Joysticks - arcadedojo.fr" description="Découvrez tous nos joysticks, leverless et flatbox sur notre site web ! | arcadedojo.fr" />
            <Navbar />
            <div className="container">
                <div className="box-1">
                    <h1>Joysticks</h1>
                    <p>Explorez notre sélection de joysticks et plongez-vous dans un monde de contrôle ultime. Que vous soyez un joueur occasionnel ou un passionné invétéré, nous avons le joystick parfait pour vous. Des designs ergonomiques aux fonctionnalités avancées, nos produits vous offrent une expérience de jeu inégalée. Parcourez notre collection dès maintenant et trouvez le joystick qui rendra votre expérience de jeu encore plus excitante !</p>
                </div>
                <div className="category-selector">
                    <label htmlFor="category" className="category-label"></label>
                    <select id="category" className="category-select" value={selectedCategory} onChange={handleCategoryChange}>
                        <option value="all">Toutes les catégories</option>
                        <option value="Leverless">Leverless</option>
                        <option value="Joystick">Joystick</option>
                        <option value="Flatbox">Flatbox</option>
                    </select>
                </div>
                <ProductsList eventOccurred={eventOccurred} limit={12} categories={selectedCategory ? [selectedCategory] : ['Leverless', 'Joystick', 'Flatbox']} />
            </div>
            <Footer />
        </>
    );
};

export default Arcade;