import React from 'react';

const Dashboard = () => {
    return (
        <div className='dashboard-container'>
            <h1>Tableau de bord</h1>
            <div className='dashboard-top'>
                <h2>Bienvenue sur DojoAdmin</h2>
                <div className='dashboard-img'>
                    <img src='/pictures/snow-gate.jpg' alt="" />
                </div>
            </div>

        </div>
    );
};

export default Dashboard;