import React, { useEffect, useState } from 'react';
import { BASE_URL } from '../../../config/config';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Loader from '../../loader/loader';

const OrdersListAdmin = () => {

    const [orders, setOrders] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const displayOrders = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/orders`, {
                    params: {
                        limit: 5,
                        page: currentPage
                    },
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    }
                });
                setOrders(response.data.data);
                setTotalPages(response.data.last_page);
                setLoading(true);
            } catch (error) {
                console.error('Erreur lors de la récupération des commandes', error);
            }
        }
        displayOrders();
    }, [currentPage])

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        };
        return date.toLocaleDateString('fr-FR', options);
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <>
            {loading
                ? <div className="table-container">
                    <h1>Liste des commandes</h1>
                    <table className="admin-table">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Email du client</th>
                                <th>N° commande</th>
                                <th>Statut</th>
                                <th>Montant</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {orders && orders.map((order) => (
                                <tr key={order.id} >
                                    <td>{formatDate(order.created_at)}</td>
                                    <td>{order.user ? order.user.email : 'Client inconnu'}</td>
                                    <td>{order.reference}</td>
                                    <td className='td-status'>
                                        {order.paymentStatus === 'paid' && (
                                            "Payée"
                                        )}
                                    </td>
                                    <td>{order.totalAmount / 100} €</td>
                                    <td>
                                        <Link to={`/admin/commande/details/${order.id}`}>
                                            <button className='order-btn'>Détails</button>
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="pagination-admin">
                        {currentPage > 1 && (
                            <button onClick={() => handlePageChange(currentPage - 1)}>Précédent</button>
                        )}
                        {Array.from({ length: Math.min(totalPages, 6) }, (_, index) => {
                            const pageOffset = Math.max(1, currentPage - 2);
                            const pageNumber = pageOffset + index;
                            return (
                                <button
                                    key={index}
                                    onClick={() => handlePageChange(pageNumber)}
                                    className={pageNumber === currentPage ? 'active' : ''}
                                >
                                    {pageNumber}
                                </button>
                            )
                        })}
                        {currentPage < totalPages && (
                            <button onClick={() => handlePageChange(currentPage + 1)}>Suivant</button>
                        )}
                    </div>
                </div>
                : <Loader />
            }
        </>
    );
};

export default OrdersListAdmin;