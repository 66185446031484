import React from 'react';
import Navbar from '../../components/navbar/navbar';

const SuccessPage = () => {
    return (
        <>
            <Navbar />
            <div className='payment'>
                <h4>Paiement réussi !</h4>
                <p>Votre paiement a été traité avec succès. Vous allez bientôt recevoir un mail de confirmation.</p>
            </div>
        </>

    );
};

export default SuccessPage;