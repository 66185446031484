import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BASE_URL } from '../../../config/config';
import { toast } from 'react-toastify';
import Loader from '../../loader/loader';
import { Link } from 'react-router-dom';

const UsersListAdmin = () => {

    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        displayUsers();
        // eslint-disable-next-line
    }, [currentPage])

    const displayUsers = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/users`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
                params: {
                    limit: 5,
                    page: currentPage,
                }
            });
            setUsers(response.data.data);
            setTotalPages(response.data.last_page);
            setLoading(true);
        } catch (error) {
            console.error('Erreur lors de la récupération des utilisateurs', error);
        }
    }

    const deleteUser = async (id) => {
        if (!window.confirm('Êtes-vous sûr de vouloir supprimer cet utilisateur ?')) {
            return
        }
        try {
            const response = await axios.delete(`${BASE_URL}/users/${id}`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                }
            });
            displayUsers();
            toast.success(response.data.message);
        } catch (error) {
            console.error('Erreur lors de la suppression de l\'utilisateur', error);
        }
    }

    const handleChangeRole = async (id, role) => {
        const formData = new FormData();
        formData.append("role", role);
        formData.append("_method", "PUT");
        try {
            const response = await axios.post(`${BASE_URL}/users/${id}`, formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                }
            );
            toast.success(response.data.message);
            displayUsers();
        } catch (error) {
            console.error('Erreur lors de la mise à jour du rôle de l\'utilisateur', error);
            toast.error('Erreur lors de la mise à jour du rôle de l\'utilisateur');
        }
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        };
        return date.toLocaleDateString('fr-FR', options);
    }

    const handleViewOrders = (user) => {
        setSelectedUser(user);
    }

    const handleCloseOrders = () => {
        setSelectedUser(null);
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <>
            {loading
                ? <div className="table-container">
                    {selectedUser ? (
                        <div className='user-orders-list'>
                            <h2>Liste des commandes</h2>
                            <div className='user-orders-top'>
                                <h3>{selectedUser.email}</h3>
                                <button className='user-btn-close' onClick={handleCloseOrders}>Fermer</button>
                            </div>
                            <table className="admin-table">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Référence</th>
                                        <th>Statut</th>
                                        <th>Livraison</th>
                                        <th>Total</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedUser && selectedUser.orders.map((order) => (
                                        <tr key={order.id} >
                                            <td>{formatDate(order.created_at)}</td>
                                            <td>{order.reference}</td>
                                            <td>
                                                {order.paymentStatus === 'paid' ? (
                                                    "Payée"
                                                ) : (
                                                    "Paiement annulé"
                                                )}
                                            </td>
                                            <td>
                                                {order.shippingAmount === 500 ? (
                                                    <p>Standard: {order.shippingAmount / 100} €</p>
                                                ) : (
                                                    <p>Express: {order.shippingAmount / 100} €</p>
                                                )}
                                            </td>
                                            <td>{order.totalAmount / 100} €</td>
                                            <td>
                                                <Link to={`/admin/commande/details/${order.id}`}>
                                                    <button className='order-btn'>Détails</button>
                                                </Link>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <div>
                            <h1>Liste des utilisateurs</h1>
                            <table className="admin-table">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Nom</th>
                                        <th>Prénom</th>
                                        <th>Email</th>
                                        <th>Adresse de facturation</th>
                                        <th>Adresse de livraison</th>
                                        <th>Commandes</th>
                                        <th></th>
                                        <th>Rôle</th>
                                        <th>Supprimer</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users && users.map((user) => (
                                        <tr key={user.id} >
                                            <td>{formatDate(user.created_at)}</td>
                                            <td>{user.lastName}</td>
                                            <td>{user.firstName}</td>
                                            <td>{user.email}</td>
                                            <td>
                                                {user.addresses.map((address) => (
                                                    <ul className='customer-addresse' key={address.addressType}>
                                                        {address.addressType === 'billing' && (
                                                            <>
                                                                <li>{address.addressLine1}</li>
                                                                <li>{address.addressLine2}</li>
                                                                <li>{address.zipcode}</li>
                                                                <li>{address.city}</li>
                                                            </>
                                                        )}
                                                    </ul>
                                                ))}
                                            </td>
                                            <td>
                                                {user.addresses.map((address) => (
                                                    <ul className='customer-addresse' key={address.addressType}>
                                                        {address.addressType === 'delivery' && (
                                                            <>
                                                                <li>{address.addressLine1}</li>
                                                                <li>{address.addressLine2}</li>
                                                                <li>{address.zipcode}</li>
                                                                <li>{address.city}</li>
                                                            </>
                                                        )}
                                                    </ul>
                                                ))}
                                            </td>
                                            <td>Total: {user.orders.length}</td>
                                            <td>
                                                <button className='customer-btn' onClick={() => handleViewOrders(user)} >Voir les commandes</button>
                                            </td>
                                            <td>
                                                <select value={user.role} onChange={(e) => handleChangeRole(user.id, e.target.value)}>
                                                    <option value="[ROLE_USER]">Utilisateur</option>
                                                    <option value="[ROLE_ADMIN]">Administrateur</option>
                                                </select>
                                            </td>
                                            <td>
                                                <div className="admin-btn-delete" onClick={() => deleteUser(user.id)} >
                                                    <svg xmlns="http://www.w3.org/2000/svg" height="22" width="18" viewBox="0 0 448 512"><path fill="#BC0B0B" d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z" /></svg>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="pagination-admin">
                                {currentPage > 1 && (
                                    <button onClick={() => handlePageChange(currentPage - 1)}>Précédent</button>
                                )}
                                {Array.from({ length: Math.min(totalPages, 6) }, (_, index) => {
                                    const pageOffset = Math.max(1, currentPage - 2);
                                    const pageNumber = pageOffset + index;
                                    return (
                                        <button
                                            key={index}
                                            onClick={() => handlePageChange(pageNumber)}
                                            className={pageNumber === currentPage ? 'active' : ''}
                                        >
                                            {pageNumber}
                                        </button>
                                    )
                                })}
                                {currentPage < totalPages && (
                                    <button onClick={() => handlePageChange(currentPage + 1)}>Suivant</button>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                : <Loader />
            }
        </>
    );
};

export default UsersListAdmin;